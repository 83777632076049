import React, { useEffect, useRef, useState } from 'react'
// import Mobile from './Mobile/mobile'
import Swal from 'sweetalert2'
import 'styles/index.scss'
const Cam = () => {
	const [playing, setPlaying] = useState(false)

	const HEIGHT = 500
	const WIDTH = 500

	const startVideo = () => {
		setPlaying(true)
		navigator.getUserMedia(
			{
				video: true,
			},
			(stream) => {
				let video = document.getElementsByClassName('app__videoFeed')[0]
				if (video) {
					video.srcObject = stream
				}
			},
			(err) => console.error(err)
		)
	}

	//reload after window cahange.

	//window event listner for blur.
	let count = 0
	window.addEventListener('blur', function (event) {
		console.log(count)
		count++
		if (count >= 5) {
			Swal.fire({
				title: 'Your Test has been submitted due to cheating.',
				confirmButtonText: `Ok`,
			}).then((result) => {
				console.log('End Quiz')
			})
		} else {
			Swal.fire({
				title: "Window Navigation detected. Your test will be submitted if you don't stop.",
				confirmButtonText: `Ok`,
			})
		}
	})

	//document event listner for full screen change.
	document.addEventListener('fullscreenchange', function (event) {
		if (document.fullscreenElement) {
			console.log('fullscreen is activated')
		} else {
			console.log('fullscreen is cancelled')
			Swal.fire({
				title: 'You cannot exit fullscreen? Test will End',
				confirmButtonText: `End Test`,
				showCancelButton: true,
			}).then((result) => {
				console.log(result)
				if (result.value) {
					console.log('Exit')
				} else {
					document.documentElement.requestFullscreen()
				}
			})
		}
	})
	const stopVideo = () => {
		setPlaying(false)
		let video = document.getElementsByClassName('app__videoFeed')[0]
		video.srcObject.getTracks()[0].stop()
	}
	const full = () => document.documentElement.requestFullscreen()
	return (
		<div className='app'>
			<div className='app__container'>
				<video height={HEIGHT} width={WIDTH} muted autoPlay className='app__videoFeed'></video>
			</div>
			<div className='app__input'>{playing ? <button onClick={stopVideo}>Stop</button> : <button onClick={startVideo}>Start</button>}</div>
			{/* <Mobile></Mobile> */}
			<button onClick={full}>full screen</button>
		</div>
	)
}

export default Cam
